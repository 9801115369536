import $ from 'cash-dom'

function changeTab(target) {
  const section = $(target).closest('.shopify-section')

  const currentTab = section.find('.tabs a.active')
  if (currentTab.length) {
    currentTab.removeClass('active')
  }
  $(target).addClass('active')

  const currentPanel = section.find('.tab-content:not(.hidden)')
  if (currentPanel.length) {
    currentPanel.addClass('hidden')
  }
  console.log($(target).attr('href'))

  const panelId = $(target).attr('href').split('#')[1]
  const panel = section.find(`#${panelId}`)
  if (panel.length) {
    panel.removeClass('hidden')
  }

  const currentHeadline = section.find('h1 span:not(.hidden)')
  if (currentHeadline.length) {
    currentHeadline.addClass('hidden')
  }

  const headline = section.find(`.${panelId}_headline`)
  if (headline.length) {
    headline.removeClass('hidden')
  }

  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?type=${panelId}`
  window.history.pushState({ path: newUrl }, '', newUrl)
}

function activateTabFromUrl() {
  const urlParams = new URLSearchParams(window.location.search)
  const typeParam = urlParams.get('type')

  if (typeParam) {
    const matchingTab = $(`.tabs a[href="#${typeParam}"]`)
    if (matchingTab.length) {
      changeTab(matchingTab)
    }
  }
}

const links = $('.tabs a')
links.on('click', (e) => {
  e.preventDefault()
  const target =
    e.target.tagName.toLowerCase() === 'a'
      ? e.target
      : $(e.target).closest('a')[0]
  changeTab(target)
})

activateTabFromUrl()
